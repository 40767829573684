<template>
  <div>
    <b-form-row>
      <b-col md="6">
        <validation-provider
          #default="validationContext"
          name="Title"
          rules="required|max:100"
        >
          <b-form-group
            label="Title"
            label-for="title"
          >
            <b-form-input
              id="title"
              v-model="commonNotification.title"
              placeholder="Notification Title"
              :state="getValidationState(validationContext)"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <b-col md="6">
        <validation-provider
          #default="validationContext"
          name="Body"
          rules="required|max:250"
        >
          <b-form-group
            label="Body"
            label-for="body"
          >
            <b-form-input
              id="body"
              v-model="commonNotification.body"
              placeholder="Enter Notification Body"
              :state="getValidationState(validationContext)"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-form-row>

    <b-form-row>
      <b-col
        v-if="!commonNotification.sendsImmediately"
        md="6"
      >
        <date-time
          v-bind="{
            id:'sends-at',
            name:'Sends at',
            rules:'required',
            config:{ enableTime:true,dateFormat: 'Y-m-d H:i', minDate: 'today'},
          }"
          :datetime.sync="commonNotification.sendsAt"
        />
      </b-col>

      <b-col
        md="6"
        class="mt-2"
      >
        <b-form-checkbox
          id="sends-immediately"
          v-model="commonNotification.sendsImmediately"
          :value="1"
          :unchecked-value="0"
          required
          class="mb-2"
        >
          Sends Immediately
        </b-form-checkbox>
      </b-col>

    </b-form-row>

  </div>
</template>
<script>
import formValidation from '@core/comp-functions/forms/form-validation'
import DateTime from '../common/FormInputs/DateTime.vue'

export default {
  name: 'NotificationForm',
  components: {
    DateTime,
  },
  props: {
    commonNotification: { type: Object, default: () => {} },
  },
  computed: {
    commonNotificationTemp: {
      get() {
        return this.commonNotification
      },
      set(value) {
        this.$emit('update:commonNotification', value)
      },
    },
  },
  setup() {
    const {
      getValidationState,
    } = formValidation()
    return { getValidationState }
  },
}
</script>
<style lang="scss">

</style>
