<template>
  <div>
    <validation-provider
      #default="validationContext"
      name="RelationType"
      :rules="rules"
    >
      <b-form-group
        :label-for="id"
      >
        <template #label>

          <span
            :class="{required:JSON.stringify(rules).includes('required')}"
          >
            Relation Type
          </span>

        </template>
        <b-form-checkbox-group
          id="relation-type"
          v-model="relationTypeProxy"
        >
          <b-form-checkbox value="primary">
            Primary Users
          </b-form-checkbox>
          <b-form-checkbox value="follow">
            Followers Users
          </b-form-checkbox>
        </b-form-checkbox-group>

        <b-form-invalid-feedback
          :state="getValidationState(validationContext)"
        >
          {{ validationContext.errors[0] }}
        </b-form-invalid-feedback>
      </b-form-group>
    </validation-provider>
  </div>
</template>
<script>
import formValidation from '@/@core/comp-functions/forms/form-validation'

export default {
  name: 'RelationTypeCheckbox',
  props: {
    relationType: { type: Array, default: () => [] },
    rules: { type: String, default: '' },
  },
  computed: {
    relationTypeProxy: {
      get() {
        return this.relationType
      },
      set(value) {
        this.$emit('update:relationType', value)
      },
    },
  },
  setup() {
    const { getValidationState } = formValidation()
    return { getValidationState }
  },
}
</script>
<style lang="">

</style>
